import { render, staticRenderFns } from "./TextBlock.vue?vue&type=template&id=763d517d&scoped=true&"
import script from "./TextBlock.vue?vue&type=script&lang=js&"
export * from "./TextBlock.vue?vue&type=script&lang=js&"
import style0 from "./TextBlock.vue?vue&type=style&index=0&id=763d517d&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "763d517d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Richtext: require('/vercel/path0/components/elements/Richtext.vue').default,AnimationCustomFadeIn: require('/vercel/path0/components/elements/Animations/CustomFadeIn.vue').default,Button: require('/vercel/path0/components/elements/Button.vue').default,Section: require('/vercel/path0/components/elements/Section.vue').default})
